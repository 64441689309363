/**
 * Depending on the max character length, it appends three dots to
 * the max of where the text is.
 * @param text Text to append three dots to.
 * @param max Max amount of characters where to append three dots to.
 * @return Substringed text to max length with an ellipsis at the end
 */
export const readLessText = (text: string, max: number): string => `${text.substring(0, max)}...`;

/**
 * Capitalizes the first letter of the string.
 *
 * @param string String to capitalize.
 * @returns Capitalized string.
 */
export const capitalizeFirstLetter = (string: string): string => {
  if (string === '') {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Returns the first letter capitalized of the string.
 *
 * @param string String to grab first letter from.
 * @returns First letter capitalized.
 */
export const capitalizedFirstLetter = (string: string): string => {
  if (string === '') {
    return string;
  }
  return string.charAt(0).toUpperCase();
};

export const getUrlFromInstagramHandle = (instagramHandle: string) =>
  `https://www.instagram.com/${instagramHandle}`;

/**
 * Replaces '&' with '_' in string to make usable for URLs
 *
 * @param string String to parse
 * @returns string with '&' replaced by '_'
 */
export const parseStringForURL = (string: string): string => {
  if (string === '') {
    return string;
  }
  if (string.includes('&')) {
    return string.replace(/&/g, '_');
  }
  return string;
};

/**
 * Replaces '_' with '&' in string to parse from URL
 *
 * @param string String to parse
 * @returns string with '_' replaced by '&'
 */
export const parseStringFromURL = (string: string): string => {
  if (string === '') {
    return string;
  }
  if (string.includes('_')) {
    return string.replace(/_/g, '&');
  }
  return string;
};

/**
 * Determines if text has been cut off (or clamped) by its overflow/webkit-line-clamp constraints
 * @param elm Parent element of text
 * @returns boolean of if text has been clamped
 */
export const isTextClamped = (elm: Element | null) => !!elm && elm.scrollHeight > elm.clientHeight;
